import { Access, CountryCode, DAC7Nexus, SupportedTaxRegions } from "@trolley/common-frontend";
import {
  Box,
  Button,
  Container,
  Form,
  FormItem,
  Grid,
  Heading,
  Input,
  InputPhone,
  LogoLoader,
  Modal,
  Radio,
  SelectCountry,
  SelectRegion,
  Space,
  Status,
  Typography,
  phoneNumberValidator,
} from "components";
import { isRegionInCountryAsyncValidator } from "pages/RecipientsPage/Details/Info";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import { useDAC7TaxMerchantYear } from "store/hooks/dac7TaxMerchantYear";
import { useAccess } from "store/hooks/user";
import { handleFormErrors, omitMaskedValues, pick, translateRegionByCountry } from "utils/helpers";
import { DAC7_LAST_YEAR } from "../..";
import { DAC7TaxMerchantYearUpdate, updateDAC7TaxMerchantYear } from "store/actions/dac7TaxMerchantYear";
import { notifySuccess } from "store/actions/notifications";
import { BaseStatus } from "store/reducers/standardReducer";
import { isProfileComplete } from ".";
import { useMerchantSettings } from "store/hooks/merchantSettings";

type FormFields = {
  businessDBAName: string;
  complete: boolean;
  year: number;
  businessName: string;
  businessNumber: string;
  city: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  country: string;
  nexus: DAC7Nexus | null;
  postalCode: string;
  regionCode: string;
  street1: string;
  street2: string;
  tinNumber: string;
  vatNumber: string;
};

function renderBusinessInformation(
  ongoingYear: boolean,
  operatingCountry: FormFields["country"],
  supportedTaxRegions: Record<SupportedTaxRegions, boolean> | undefined,
) {
  let businessRegistrationNumber = "Business Registration Number";

  // Australia specific fields
  if (supportedTaxRegions?.AU) {
    businessRegistrationNumber = "Australian Business Number";

    if (operatingCountry !== CountryCode.AU) {
      businessRegistrationNumber += " or ATO Reference Number";
    }
  }

  // Show everything by default or show everything if another region is supported
  const showTinAndVat =
    !supportedTaxRegions ||
    Object.values(supportedTaxRegions).every((region) => region === false) ||
    Object.entries(supportedTaxRegions)
      .filter((taxRegions) => taxRegions[0] !== CountryCode.AU)
      .some((taxRegions) => taxRegions[1] === true);

  return (
    <Grid padding={["medium", "none"]}>
      {showTinAndVat && (
        <>
          <Grid.Item xs={24} sm={12}>
            <FormItem label="Business Tax Identification Number" name="tinNumber" required>
              <Input name="tinNumber" disabled={!ongoingYear} />
            </FormItem>
          </Grid.Item>
          <Grid.Item xs={24} sm={12}>
            <FormItem label="Business VAT Number (optional)" name="vatNumber">
              <Input name="vatNumber" disabled={!ongoingYear} />
            </FormItem>
          </Grid.Item>
        </>
      )}

      <Grid.Item xs={24} sm={12}>
        <FormItem label={businessRegistrationNumber} name="businessNumber" required>
          <Input name="businessNumber" disabled={!ongoingYear} />
        </FormItem>
      </Grid.Item>
    </Grid>
  );
}

export default function MerchantDAC7TaxInfo() {
  const params = useParams<{ taxYear: string }>();
  const [form] = Form.useForm<FormFields>();
  const accessTaxEOYWrite = useAccess(Access.TAX_EOY_WRITE);
  const country = Form.useWatch(["country"], form);
  const taxYear = Number(params.taxYear);
  const { data: dac7TaxMerchantYear, status: dac7TaxMerchantYearStatus } = useDAC7TaxMerchantYear(taxYear);
  const { data: merchantSettings, status: merchantSettingsStatus } = useMerchantSettings();
  const ongoingYear = taxYear >= DAC7_LAST_YEAR;
  const { data: previousDAC7TaxMerchantYear, status: previousDAC7TaxMerchantYearStatus } = useDAC7TaxMerchantYear(ongoingYear ? taxYear - 1 : undefined);

  useEffect(() => {
    if (dac7TaxMerchantYear) {
      form.setFieldsValue(dac7TaxMerchantYear as FormFields);
    }
  }, [dac7TaxMerchantYear]);

  async function onFinish(values: FormFields) {
    if (ongoingYear && dac7TaxMerchantYear) {
      const keysChanged = Object.keys(omitMaskedValues(values)).filter((key) => {
        if (key in dac7TaxMerchantYear) {
          if (Array.isArray(dac7TaxMerchantYear[key])) {
            return values[key]?.join("") !== dac7TaxMerchantYear[key]?.filter((v: string) => v).join("");
          }

          return dac7TaxMerchantYear[key] !== values[key];
        }

        return true;
      });
      if (keysChanged.length) {
        const data = pick(values, keysChanged) as DAC7TaxMerchantYearUpdate;
        try {
          const newMerchantDAC7TaxYear = await updateDAC7TaxMerchantYear(data, taxYear);
          form.setFieldsValue(newMerchantDAC7TaxYear as FormFields);
          notifySuccess("Business Tax Profile saved");
        } catch (errors) {
          handleFormErrors(errors, form);
        }
      } else {
        notifySuccess("Nothing to save");
      }
    }
  }

  return (
    <Container padding="none" maxWidth="lg">
      <Helmet>
        <title>Business Tax Profile</title>
      </Helmet>
      <Heading
        tag="h2"
        extraActions={
          previousDAC7TaxMerchantYear &&
          previousDAC7TaxMerchantYearStatus === BaseStatus.LOADED &&
          isProfileComplete(previousDAC7TaxMerchantYear) && (
            <Button
              type="text"
              onClick={() => {
                Modal.confirm({
                  title: "Copy over last year's profile",
                  content: "Would you like to copy over last year's business tax profile? The form below will be prefilled with last year's profile.",
                  okText: "Yes, Copy over last year's profile",
                  onOk() {
                    form.setFieldsValue(previousDAC7TaxMerchantYear as FormFields);
                  },
                });
              }}
            >
              Copy over last year's business tax profile?
            </Button>
          )
        }
      >
        <Space>
          Business Tax Profile
          {!isProfileComplete(dac7TaxMerchantYear) && !dac7TaxMerchantYear?.complete && <Status type="incomplete" />}
          {(isProfileComplete(dac7TaxMerchantYear) || dac7TaxMerchantYear?.complete) && <Status type="complete" />}
        </Space>
      </Heading>

      <LogoLoader spinning={dac7TaxMerchantYearStatus === BaseStatus.LOADING && merchantSettingsStatus === BaseStatus.LOADING}>
        {/* <LogoLoader spinning={false}> */}
        {dac7TaxMerchantYear && (
          <Form form={form} onFinish={onFinish} initialValues={dac7TaxMerchantYear} validateTrigger="onBlur" disabled={!accessTaxEOYWrite}>
            <Box header="Business Name & Address" flat>
              <p>
                Provide the business name & address for DAC7 filing. This name and address will appear on your Seller's Earnings Summary PDF and in the XML File
                that is used for filing with the EU member states.
              </p>
              <Grid padding={["medium", "none"]}>
                <Grid.Item xs={24} sm={12}>
                  <FormItem label="Business Legal Name" name="businessName">
                    <Input name="businessName" disabled={!ongoingYear} />
                  </FormItem>
                </Grid.Item>
                <Grid.Item xs={24} sm={12}>
                  <FormItem label="Doing-Business-As Name (if different)" name="businessDBAName">
                    <Input name="businessDBAName" disabled={!ongoingYear} />
                  </FormItem>
                </Grid.Item>
                <Grid.Item xs={24}>
                  <FormItem label="Country" name="country" validateTrigger={["onChange", "onBlur"]}>
                    <SelectCountry disabled={!ongoingYear} type="address" />
                  </FormItem>
                </Grid.Item>

                {country && (
                  <>
                    <Grid.Item xs={24} sm={12}>
                      <FormItem label="Street Address" name="street1">
                        <Input name="street1" autoComplete="street-address" disabled={!ongoingYear} />
                      </FormItem>
                    </Grid.Item>
                    <Grid.Item xs={24} sm={12}>
                      <FormItem label="City" name="city">
                        <Input name="city" autoComplete="address-level1" disabled={!ongoingYear} />
                      </FormItem>
                    </Grid.Item>
                    <Grid.Item xs={24} sm={7}>
                      <FormItem
                        label={translateRegionByCountry(country)}
                        name="regionCode"
                        dependencies={["country"]}
                        rules={[isRegionInCountryAsyncValidator("mailingCountry")]}
                        validateTrigger={["onChange", "onBlur"]}
                      >
                        <SelectRegion country={country} disabled={!ongoingYear} allowClear />
                      </FormItem>
                    </Grid.Item>
                    <Grid.Item xs={24} sm={5}>
                      <FormItem label="Zip/Postal Code" name="postalCode" dependencies={["country"]}>
                        <Input name="postalCode" autoComplete="postal-code" disabled={!ongoingYear} />
                      </FormItem>
                    </Grid.Item>
                  </>
                )}
              </Grid>
            </Box>

            <Box header="Contact Information" flat>
              <p>DAC7 Reporting Requires a contact name, number and email to be submitted with the file. Please enter the information below.</p>
              <Grid padding={["medium", "none"]}>
                <Grid.Item xs={24} sm={12}>
                  <FormItem label="Contact Name" name="contactName">
                    <Input name="contactName" disabled={!ongoingYear} />
                  </FormItem>
                </Grid.Item>
                <Grid.Item xs={24} sm={12}>
                  <FormItem label="Contact Email" name="contactEmail" rules={[{ type: "email", message: "Enter a valid email address" }]}>
                    <Input name="contactEmail" autoComplete="email" disabled={!ongoingYear} />
                  </FormItem>
                </Grid.Item>
                <Grid.Item xs={24} sm={12}>
                  <FormItem label="Phone" name="contactPhone" rules={[phoneNumberValidator()]}>
                    <InputPhone name="contactPhone" disabled={!ongoingYear} />
                  </FormItem>
                </Grid.Item>
              </Grid>
            </Box>

            <Box header="Business Identification Numbers" flat>
              <p>
                Provide your business' registration information for tax reporting. Please provide numbers that were issued in the country you listed in the
                Business Name & Address section above.
              </p>
              {renderBusinessInformation(ongoingYear, country, merchantSettings?.supportedTaxRegions)}
            </Box>

            <Box header="" flat>
              <p>
                In DAC7 reporting, each{" "}
                <Typography.Text inline weight="bold">
                  Reporting Platform Operator (RPO)
                </Typography.Text>{" "}
                must identify their nexus as it relates to the EU. Please choose one of the below options to indicate your businesses nexus.
              </p>
              <Grid padding={["medium", "none"]}>
                <Grid.Item xs={24}>
                  <FormItem name="nexus">
                    <Radio.Group
                      disabled={!ongoingYear}
                      optionType="card"
                      gap="large"
                      direction="horizontal"
                      isGrid
                      options={[
                        {
                          label: (
                            <>
                              <Typography.Paragraph>The RPO's residence for tax purposes is in a Member State</Typography.Paragraph>
                              <Typography.Paragraph type="secondary">
                                For most businesses based in the EU, this would be the applicable choice
                              </Typography.Paragraph>
                            </>
                          ),
                          value: "RPONEX1",
                        },
                        {
                          label: (
                            <>
                              <Typography.Paragraph>
                                The{" "}
                                <Typography.Text inline weight="bold">
                                  Reporting Platform Operator (RPO)
                                </Typography.Text>{" "}
                                does not have residence for tax purposes but it is incorporated under the laws of the EU Member State
                              </Typography.Paragraph>
                              <Typography.Paragraph type="secondary">
                                For businesses that are incorporated in the EU, but are not claiming tax residency.
                              </Typography.Paragraph>
                            </>
                          ),
                          value: "RPONEX2",
                        },
                        {
                          label: (
                            <>
                              <Typography.Paragraph>
                                The{" "}
                                <Typography.Text inline weight="bold">
                                  Reporting Platform Operator (RPO)
                                </Typography.Text>{" "}
                                does not have residence for tax purposes but it has its place of management (including effective management) in the EU Member
                                State.
                              </Typography.Paragraph>
                              <Typography.Paragraph type="secondary">
                                For businesses that managed from the EU, but not claiming tax residency.
                              </Typography.Paragraph>
                            </>
                          ),
                          value: "RPONEX3",
                        },
                        {
                          label: (
                            <>
                              <Typography.Paragraph>
                                The{" "}
                                <Typography.Text inline weight="bold">
                                  Reporting Platform Operator (RPO)
                                </Typography.Text>{" "}
                                does not have residence for tax purposes but it has a permanent establishment in the EU Member State and it is not a Qualified
                                Non Union Platform Operator.
                              </Typography.Paragraph>
                              <Typography.Paragraph type="secondary">
                                For businesses that have a permanent establishment in the EU, but are not claiming tax residency.
                              </Typography.Paragraph>
                            </>
                          ),
                          value: "RPONEX4",
                        },
                        {
                          label: (
                            <>
                              <Typography.Paragraph>
                                The{" "}
                                <Typography.Text inline weight="bold">
                                  Reporting Platform Operator (RPO)
                                </Typography.Text>{" "}
                                does not have residence for tax purposes but the RPO is neither resident for tax purposes, nor incorporated or managed in the EU
                                Member State, nor has a permanent establishment in the EU Member State but it facilitates the carrying out of a Relevant
                                Activity by Reportable Sellers or a Relevant Activity involving the rental of immovable property located in the EU Member State
                                and it is not a Qualified Non-Union Platform Operator.
                              </Typography.Paragraph>
                              <Typography.Paragraph type="secondary">
                                For businesses with no ties at all to the EU, but they are paying EU-Based Sellers for DAC7 Reportable Activities.
                              </Typography.Paragraph>
                            </>
                          ),
                          value: "RPONEX5",
                        },
                      ]}
                    />
                  </FormItem>
                </Grid.Item>
              </Grid>
            </Box>

            {accessTaxEOYWrite && ongoingYear && (
              <Form.Control shouldUpdate>
                {({ isFieldsTouched }) => (
                  <Typography.Text align="right">
                    <Button htmlType="submit" type={isFieldsTouched() ? "primary" : "default"} loading={dac7TaxMerchantYearStatus === BaseStatus.LOADING}>
                      Save Tax Profile
                    </Button>
                  </Typography.Text>
                )}
              </Form.Control>
            )}
          </Form>
        )}
      </LogoLoader>
    </Container>
  );
}
