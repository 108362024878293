import { PaymentReason } from "../../store/actions/paymentDac7Taxes";

export const PAYMENT_REASON_DETAILS: Record<PaymentReason, { name: string }> = {
  [PaymentReason.CONTENT_CREATION]: {
    name: "Content Creation",
  },
  [PaymentReason.OTHER_SERVICES]: {
    name: "Other Services",
  },
  [PaymentReason.PEER_TO_PEER]: {
    name: "Peer to Peer",
  },
  [PaymentReason.SKILLS_PROVISIONS]: {
    name: "Skills Provisions",
  },
  [PaymentReason.SUBSCRIPTION]: {
    name: "Subscription",
  },
  [PaymentReason.TASKING]: {
    name: "Tasking",
  },
};
