import { Access, CountryCode, Tax1099KFilerType } from "@trolley/common-frontend";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Form,
  FormItem,
  Grid,
  Heading,
  Input,
  InputPhone,
  LogoLoader,
  Modal,
  phoneNumberValidator,
  Radio,
  Select,
  SelectCountry,
  SelectRegion,
  Text,
} from "components";
import { isRegionInCountryAsyncValidator } from "pages/RecipientsPage/Details/Info";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import { notifySuccess } from "store/actions/notifications";
import { TaxMerchantYearUpdate, updateTaxMerchantYear } from "store/actions/taxMerchantYear";
import { useMerchantTaxYear } from "store/hooks/taxMerchantYear";
import { useAccess } from "store/hooks/user";
import { BaseStatus } from "store/reducers/standardReducer";
import { handleFormErrors, omitMaskedValues, pick, translateRegionByCountry } from "utils/helpers";
import { isProfileComplete } from ".";
import { LAST_YEAR } from "../..";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

/**
 * https://www.irs.gov/pub/irs-prior/i1042s--2022.pdf
 *
 * Comment out all code that will never be relevant to our merchants. May be useful in future
 * https://paymentrails.atlassian.net/browse/ENG-807
 */
const chapter3Codes = [
  { value: "15", label: "Corporation" }, // must be at top
  // { value: "03", label: "Territory FI treated as U.S. Person" },
  // { value: "04", label: "Territory FI - not treated as U.S. Person" },
  { value: "05", label: "U.S. branch - treated as U.S. Person" },
  { value: "06", label: "U.S. branch - not treated as U.S. Person" },
  // { value: "07", label: "U.S. branch - ECI presumption applied" },
  // { value: "09", label: "Withholding Foreign Partnership" },
  // { value: "11", label: "Withholding Foreign Trust" },
  // { value: "12", label: "Qualified Intermediary" },
  // { value: "13", label: "Qualified Securities Lender - Qualified Intermediary" },
  // { value: "14", label: "Qualified Securities Lender - Other" },
  // { value: "16", label: "Individual" },
  { value: "17", label: "Estate" },
  { value: "18", label: "Private Foundation" },
  { value: "19", label: "International Organization" },
  { value: "20", label: "Tax Exempt Organization (Section 501(c) entities)" },
  // { value: "21", label: "Unknown Recipient" },
  // { value: "22", label: "Artist or Athlete" },
  { value: "23", label: "Pension" },
  // { value: "24", label: "Foreign Central Bank of Issue" },
  // { value: "26", label: "Hybrid entity making Treaty Claim" },
  // { value: "35", label: "Qualified Derivatives Dealer" },
  // { value: "36", label: "Foreign Government - Integral Part" },
  // { value: "37", label: "Foreign Government - Controlled Entity" },
  // { value: "38", label: "Publicly Traded Partnership" },

  /** deprioritize the following 3 */
  { value: "25", label: "Nonqualified Intermediary" },
  { value: "08", label: "Partnership other than Withholding Foreign Partnership or Publicly Traded Partnership" },
  { value: "10", label: "Trust other than Withholding Foreign Trust" },
];

/**
 * Comment out all code that will never be relevant to our merchants. May be useful in future
 * https://paymentrails.atlassian.net/browse/ENG-806
 */
const chapter4Codes = [
  // { value: "01", label: "U.S. Withholding Agent - FI" },
  { value: "02", label: "U.S. Withholding Agent - Other" },
  // { value: "03", label: "Territory FI treated as U.S. Person" },
  // { value: "04", label: "Territory FI - not treated as U.S. Person" },
  // { value: "05", label: "Participating FFI - Other" },
  // { value: "06", label: "Participating FFI - Reporting Model 2 FFI" },
  // { value: "07", label: "Registered Deemed-Compliant FFI - Reporting Model 1 FFI" },
  // { value: "08", label: "Registered Deemed-Compliant FFI - Sponsored Entity" },
  // { value: "09", label: "Registered Deemed-Compliant FFI - Other" },
  // { value: "10", label: "Certified Deemed-Compliant FFI - Other" },
  // { value: "11", label: "Certified Deemed-Compliant FFI - FFI with Low Value Accounts" },
  // { value: "12", label: "Certified Deemed-Compliant FFI - Non-Registering Local Bank" },
  // { value: "13", label: "Certified Deemed-Compliant FFI - Sponsored Entity" },
  // { value: "14", label: "Certified Deemed-Compliant FFI - Certified Deemed-Compliant FFI - Investment entity that does not maintain financial accounts" },
  // { value: "15", label: "Nonparticipating FFI" },
  // { value: "16", label: "Owner-Documented FFI" },
  { value: "17", label: "U.S. branch - treated as U.S. Person" },
  { value: "18", label: "U.S. Branch - not treated as U.S. person (reporting under section 1471)" },
  { value: "19", label: "Passive NFFE identifying Substantial U.S. Owners" },
  { value: "20", label: "Passive NFFE with no Substantial U.S. Owners" },
  { value: "21", label: "Publicly Traded NFFE or Affiliate of Publicly Traded NFFE" },
  { value: "22", label: "Active NFFE" },
  // { value: "23", label: "Individual" },
  { value: "24", label: "Section 501(c) Entities" },
  { value: "25", label: "Excepted Territory NFFE" },
  { value: "26", label: "Excepted NFFE - Other" },
  { value: "27", label: "Exempt Beneficial Owner" },
  { value: "28", label: "Entity Wholly Owned By Exempt Beneficial Owners" },
  // { value: "29", label: "Unknown Recipient" },
  // { value: "30", label: "Recalcitrant Account Holder" },
  // { value: "31", label: "Nonreporting IGA FFI" },
  { value: "32", label: "Direct reporting NFFE" },
  { value: "33", label: "U.S. reportable account" },
  { value: "34", label: "Nonconsenting U.S. account" },
  { value: "35", label: "Sponsored direct reporting NFFE" },
  // { value: "36", label: "Excepted Inter-affiliate FFI" },
  // { value: "37", label: "Undocumented Preexisting Obligation" },
  // { value: "38", label: "U.S. branch - ECI presumption applied" },
  // { value: "39", label: "Account Holder of Excluded Financial Account" },
  // { value: "40", label: "Passive NFFE reported by FFI" },
  { value: "41", label: "NFFE subject to 1472 withholding" },
  // { value: "50", label: "U.S. Withholding Agent-Foreign branch of FI" },
];

const tinPattern = /^[0-9]{2}-?[0-9]{7}$/;

type FormFields = {
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  organizationName: string;
  mailingAddress: string;
  mailingCity: string;
  mailingCountry: string;
  mailingRegion: string;
  mailingPostalCode: string;
  nonUSentity: boolean;
  taxUsId?: string; // required for 1099
  // irsControlCode?: string; // required for 1099 FIRE E-Filing
  taxForeignId?: string; // optional for 1042
  tax1042chapter3code: string; // required for 1042
  tax1042chapter4code: string; // required for 1042
  // irsControlCode1042?: string; // required for 1042 FIRE E-Filing
  USmailingAddress?: string;
  USmailingCity?: string;
  USmailingRegion?: string;
  USmailingPostalCode?: string;
  USmailingCountry?: CountryCode.US;
};

const US_MAPPED_FIELDS: Partial<Record<keyof FormFields, string>> = {
  mailingAddress: "USmailingAddress",
  mailingCity: "USmailingCity",
  mailingRegion: "USmailingRegion",
  mailingPostalCode: "USmailingPostalCode",
};

export default function MerchantTaxInfo() {
  const params = useParams<{ taxYear: string }>();
  const [form] = Form.useForm<FormFields>();
  const accessTaxEOYWrite = useAccess(Access.TAX_EOY_WRITE);
  const mailingCountry = Form.useWatch(["mailingCountry"], form);
  const taxYear = Number(params.taxYear);
  const { data: taxMerchantYear, status: taxMerchantYearStatus } = useMerchantTaxYear(taxYear);
  const ongoingYear = taxYear >= LAST_YEAR;
  const { data: previousTaxMerchantYear, status: previousTaxMerchantYearStatus } = useMerchantTaxYear(ongoingYear ? taxYear - 1 : undefined);
  const hasNEC = taxYear >= 2020 || ""; // 1099-NEC is available only starting from 2020
  const showTCC = taxYear < 2022; // For Tax Year 2022, Trolley has its own TCC code to generate E-File.
  const show1099K = taxYear >= 2023;
  const requireUSReturnAddress = taxYear >= 2024; // US Return Mailing Address is required for 2024 and onwards
  const [isSameMailingUSAddress, setIsSameMailingUSAddress] = React.useState<boolean>(true);
  const shouldUSMailingAddressBeEqual = isSameMailingUSAddress && mailingCountry === CountryCode.US;

  useEffect(() => {
    if (taxMerchantYear) {
      let taxMerchantYearValues = taxMerchantYear as FormFields;
      if (requireUSReturnAddress) {
        taxMerchantYearValues = { ...taxMerchantYearValues, USmailingCountry: CountryCode.US };
      }

      form.setFieldsValue(taxMerchantYearValues as FormFields);
    }
  }, [taxMerchantYear]);

  async function onFinish(values: FormFields) {
    if (ongoingYear && taxMerchantYear) {
      const keysChanged = Object.keys(omitMaskedValues(values)).filter((key) => {
        if (key in taxMerchantYear) {
          if (Array.isArray(taxMerchantYear[key])) {
            return values[key]?.join("") !== taxMerchantYear[key]?.filter((v: string) => v).join("");
          }

          return taxMerchantYear[key] !== values[key];
        }

        return false;
      });

      if (keysChanged.length) {
        const data = pick(values, keysChanged) as TaxMerchantYearUpdate;

        try {
          const newMerchantTaxYear = await updateTaxMerchantYear(data, taxYear);
          form.setFieldsValue(newMerchantTaxYear as FormFields);
          notifySuccess("Business Tax Profile saved");
        } catch (errors) {
          handleFormErrors(errors, form);
        }
      } else {
        notifySuccess("Nothing to save");
      }
    }
  }

  const onChangeSameMailingAddress = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    if (checked) {
      form.setFieldsValue({
        USmailingAddress: form.getFieldValue("mailingAddress"),
        USmailingCity: form.getFieldValue("mailingCity"),
        USmailingRegion: form.getFieldValue("mailingRegion"),
        USmailingPostalCode: form.getFieldValue("mailingPostalCode"),
      });
    } else {
      form.setFieldsValue({
        USmailingAddress: "",
        USmailingCity: "",
        USmailingRegion: "",
        USmailingPostalCode: "",
      });
    }
    setIsSameMailingUSAddress(checked);
  };

  const onValuesChange = (changedValues: FormFields) => {
    const changedField = Object.keys(changedValues)[0] as keyof FormFields;

    // If US Mailing Address is the same as Business Address and I change from one country to US when already
    // have some input, should overwrite
    if (isSameMailingUSAddress && changedField === "mailingCountry" && changedValues.mailingCountry === CountryCode.US) {
      form.setFieldsValue({
        USmailingAddress: form.getFieldValue("mailingAddress"),
        USmailingCity: form.getFieldValue("mailingCity"),
        USmailingRegion: form.getFieldValue("mailingRegion"),
        USmailingPostalCode: form.getFieldValue("mailingPostalCode"),
      });
    }

    // Chaging values in US Mailing Address should be the same in US Returning if
    // country === US and isSameMailingUSAddress is true
    if (shouldUSMailingAddressBeEqual) {
      if (changedField && Object.prototype.hasOwnProperty.call(US_MAPPED_FIELDS, changedField)) {
        const usMapKey = US_MAPPED_FIELDS[changedField] as keyof FormFields;
        form.setFieldsValue({
          [usMapKey]: changedValues[changedField],
        });
      }
    }
  };

  return (
    <Container padding="none" maxWidth="lg">
      <Helmet>
        <title>Business Tax Profile</title>
      </Helmet>
      <Heading
        tag="h2"
        extraActions={
          previousTaxMerchantYear &&
          previousTaxMerchantYearStatus === BaseStatus.LOADED &&
          isProfileComplete(previousTaxMerchantYear) && (
            <Button
              type="text"
              onClick={() => {
                Modal.confirm({
                  title: "Copy over last year's profile",
                  content: "Would you like to copy over last year's business tax profile? The form below will be prefilled with last year's profile.",
                  okText: "Yes, Copy over last year's profile",
                  onOk() {
                    let parsedPreviousTaxMerchantYear = previousTaxMerchantYear as FormFields;
                    // 2023 to 2024 case, as this will not be completed for 2023. Other years should copy properly
                    if (!parsedPreviousTaxMerchantYear.USmailingCountry) {
                      parsedPreviousTaxMerchantYear = {
                        ...parsedPreviousTaxMerchantYear,
                        USmailingCountry: CountryCode.US,
                      };
                      if (parsedPreviousTaxMerchantYear.mailingCountry === CountryCode.US) {
                        parsedPreviousTaxMerchantYear = {
                          ...parsedPreviousTaxMerchantYear,
                          USmailingAddress: parsedPreviousTaxMerchantYear.mailingAddress,
                          USmailingCity: parsedPreviousTaxMerchantYear.mailingCity,
                          USmailingRegion: parsedPreviousTaxMerchantYear.mailingRegion,
                          USmailingPostalCode: parsedPreviousTaxMerchantYear.mailingPostalCode,
                        };
                        setIsSameMailingUSAddress(true);
                      }
                    }
                    form.setFieldsValue(parsedPreviousTaxMerchantYear);
                  },
                });
              }}
            >
              Copy over last year's business tax profile?
            </Button>
          )
        }
      >
        Business Tax Profile
      </Heading>

      <LogoLoader spinning={taxMerchantYearStatus === BaseStatus.LOADING}>
        {taxMerchantYear && (
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={taxMerchantYear}
            validateTrigger="onBlur"
            disabled={!accessTaxEOYWrite}
            onValuesChange={onValuesChange}
          >
            <Box header="Contact Information" flat>
              <p>Provide your business's contact information for Internal Revenue Service (IRS) filing.</p>
              <Grid padding={["medium", "none"]}>
                <Grid.Item xs={24} sm={12}>
                  <FormItem label="Contact Name" name="contactName">
                    <Input name="contactName" disabled={!ongoingYear} />
                  </FormItem>
                </Grid.Item>
                <Grid.Item xs={24} sm={12}>
                  <FormItem label="Contact Phone Number" name="contactPhone" rules={[phoneNumberValidator()]}>
                    <InputPhone name="contactPhone" disabled={!ongoingYear} />
                  </FormItem>
                </Grid.Item>
                <Grid.Item xs={24} sm={12}>
                  <FormItem label="Email Address" name="contactEmail" rules={[{ type: "email", message: "Enter a valid email address" }]}>
                    <Input name="contactEmail" autoComplete="email" disabled={!ongoingYear} />
                  </FormItem>
                </Grid.Item>
              </Grid>
            </Box>
            <Box header="Business Mailing Address" flat>
              <p>
                Provide the business mailing address for IRS filing. This address is also used to populate the 1099-MISC{hasNEC && ", 1099-NEC"} and 1042-S
                forms' printing and return addresses.
              </p>
              <Grid padding={["medium", "none"]}>
                <Grid.Item xs={24} sm={12}>
                  <FormItem label="Business Legal Name" name="organizationName">
                    <Input name="organizationName" disabled={!ongoingYear} />
                  </FormItem>
                </Grid.Item>
                <Grid.Item xs={24}>
                  <FormItem label="Country" name="mailingCountry" validateTrigger={["onChange", "onBlur"]}>
                    <SelectCountry disabled={!ongoingYear} type="address" />
                  </FormItem>
                </Grid.Item>
                {mailingCountry && (
                  <>
                    <Grid.Item xs={24} sm={12}>
                      <FormItem label="Street Address" name="mailingAddress">
                        <Input name="mailingAddress" autoComplete="street-address" disabled={!ongoingYear} />
                      </FormItem>
                    </Grid.Item>
                    <Grid.Item xs={24} sm={12}>
                      <FormItem label="City" name="mailingCity">
                        <Input name="mailingCity" autoComplete="address-level1" disabled={!ongoingYear} />
                      </FormItem>
                    </Grid.Item>
                    <Grid.Item xs={24} sm={7}>
                      <FormItem
                        label={translateRegionByCountry(mailingCountry)}
                        name="mailingRegion"
                        dependencies={["mailingCountry"]}
                        rules={[isRegionInCountryAsyncValidator("mailingCountry")]}
                        validateTrigger={["onChange", "onBlur"]}
                      >
                        <SelectRegion country={mailingCountry} disabled={!ongoingYear} allowClear />
                      </FormItem>
                    </Grid.Item>
                    <Grid.Item xs={24} sm={5}>
                      <FormItem label="Zip/Postal Code" name="mailingPostalCode" dependencies={["mailingCountry"]}>
                        <Input name="mailingPostalCode" autoComplete="postal-code" disabled={!ongoingYear} />
                      </FormItem>
                    </Grid.Item>
                  </>
                )}
              </Grid>
            </Box>

            {requireUSReturnAddress && (
              <Box header="US Return Mailing Address" flat>
                <p>Please provide a U.S. return address for undeliverable mailed statements to be received and processed.</p>
                {mailingCountry && mailingCountry === CountryCode.US && (
                  <div style={{ marginBottom: "1em" }}>
                    <Checkbox checked={isSameMailingUSAddress} onChange={onChangeSameMailingAddress}>
                      Same as business address
                    </Checkbox>
                  </div>
                )}
                <Grid padding={["medium", "none"]}>
                  {/* Business Legal Name same for mail and return mail address */}
                  <Grid.Item xs={24} sm={12}>
                    <FormItem label="Business Legal Name" name="organizationName">
                      <Input name="organizationName" disabled />
                    </FormItem>
                  </Grid.Item>
                  {/* Needs to always return to US */}
                  <Grid.Item xs={24}>
                    <FormItem label="Country" name="USmailingCountry">
                      <SelectCountry disabled type="address" />
                    </FormItem>
                  </Grid.Item>
                  <Grid.Item xs={24} sm={12}>
                    <FormItem label="Street Address" name="USmailingAddress">
                      <Input name="USmailingAddress" autoComplete="street-address" disabled={!ongoingYear || shouldUSMailingAddressBeEqual} />
                    </FormItem>
                  </Grid.Item>
                  <Grid.Item xs={24} sm={12}>
                    <FormItem label="City" name="USmailingCity">
                      <Input name="USmailingCity" autoComplete="address-level1" disabled={!ongoingYear || shouldUSMailingAddressBeEqual} />
                    </FormItem>
                  </Grid.Item>
                  <Grid.Item xs={24} sm={7}>
                    <FormItem
                      label={translateRegionByCountry(CountryCode.US)}
                      name="USmailingRegion"
                      rules={[isRegionInCountryAsyncValidator("USmailingCountry")]}
                    >
                      <SelectRegion country={CountryCode.US} disabled={!ongoingYear || shouldUSMailingAddressBeEqual} allowClear />
                    </FormItem>
                  </Grid.Item>
                  <Grid.Item xs={24} sm={5}>
                    <FormItem label="Zip/Postal Code" name="USmailingPostalCode">
                      <Input name="USmailingPostalCode" autoComplete="postal-code" disabled={!ongoingYear || shouldUSMailingAddressBeEqual} />
                    </FormItem>
                  </Grid.Item>
                </Grid>
              </Box>
            )}

            <Box header={<>Tax Identification for 1099-MISC{hasNEC && ", and 1099-NEC"} Tax Statements</>} flat>
              <p>
                Complete the following section in order to generate 1099-MISC{hasNEC && ", and 1099-NEC"} tax statements, and produce the E-File for the IRS.
              </p>
              <Grid padding={["medium", "none"]}>
                <Grid.Item xs={24}>
                  <FormItem label="Entity Type" name="nonUSentity">
                    <Radio.Group
                      disabled={!ongoingYear}
                      style={{ display: "flex", flexDirection: "column" }}
                      options={[
                        { value: false, label: "I certify that we are a US Entity." },
                        {
                          value: true,
                          label: "I certify that we are a Non-US Entity.",
                        },
                      ]}
                    />
                  </FormItem>
                </Grid.Item>
                <Grid.Item xs={24} sm={showTCC ? 12 : 24}>
                  <FormItem
                    label="Taxpayer Identification Number (EIN, ITIN, TIN)"
                    name="taxUsId"
                    rules={[{ pattern: tinPattern, message: "Please enter a valid TIN" }]}
                  >
                    <Input name="taxUsId" placeholder="XX-XXXXXXX" disabled={!ongoingYear} />
                  </FormItem>
                </Grid.Item>
                {showTCC && (
                  <Grid.Item xs={24} sm={12}>
                    <FormItem label="IRS FIRE Transmitter Control Code (TCC) for 1099s" name="irsControlCode">
                      <Input name="irsControlCode" disabled placeholder="XXXXX" />
                    </FormItem>
                  </Grid.Item>
                )}
              </Grid>
            </Box>

            <Box header="Tax Identification for 1042-S Tax Statements" flat>
              <p>Complete the following section in order to generate 1042-S tax statements, and produce the E-File for the IRS.</p>
              <Grid padding={["medium", "none"]}>
                <Grid.Item xs={24} sm={showTCC ? 12 : 24}>
                  <FormItem label="Foreign Taxpayer Identification Number" name="taxForeignId">
                    <Input name="taxForeignId" disabled={!ongoingYear} />
                  </FormItem>
                </Grid.Item>
                {showTCC && (
                  <Grid.Item xs={24} sm={12}>
                    <FormItem label="IRS FIRE Transmitter Control Code (TCC) for 1042-S" name="irsControlCode1042">
                      <Input name="irsControlCode1042" disabled placeholder="XXXXX" />
                    </FormItem>
                  </Grid.Item>
                )}
                <Grid.Item xs={24} sm={12}>
                  <FormItem
                    label="Chapter 3 Status Code"
                    tooltip="Please select your Chapter 3 Status Code. For many companies this is “Corporation”, however please check with your tax advisor if you are uncertain."
                    name="tax1042chapter3code"
                    validateTrigger={["onChange", "onBlur"]}
                    normalize={(v) => v || null}
                  >
                    <Select placeholder="Select a status code" disabled={!ongoingYear} allowClear>
                      {chapter3Codes.map((code) => (
                        <Select.Option key={code.value} value={code.value}>
                          {code.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Grid.Item>
                <Grid.Item xs={24} sm={12}>
                  <FormItem
                    label="Chapter 4 Status Code"
                    name="tax1042chapter4code"
                    validateTrigger={["onChange", "onBlur"]}
                    normalize={(v) => v || null}
                    tooltip={
                      <>
                        Please select your Chapter 4 Status Code. For many companies this is <strong>"U.S. Withholding Agent - Other"</strong>, however please
                        check with your tax advisor if you are uncertain.
                      </>
                    }
                  >
                    <Select placeholder="Select a status code" disabled={!ongoingYear} allowClear>
                      {chapter4Codes.map((code) => (
                        <Select.Option key={code.value} value={code.value}>
                          {code.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Grid.Item>
              </Grid>
            </Box>

            {show1099K && (
              <Box header="1099-K Filer Type" flat>
                <p>Complete the following section in order to generate 1099-K tax statements, and produce the E-File for the IRS.</p>
                <FormItem
                  label="Filer Type"
                  name="tax1099KFilerType"
                  tooltip="A PSE is typically a Payer that is sending payments directly to the Payee - this is the typical choice for online marketplaces. A EPF is when the payer is acting as a 3rd party between parties."
                >
                  <Radio.Group
                    options={[
                      { value: Tax1099KFilerType.PSE, label: "Payment Settlement Entity (PSE)" },
                      {
                        value: Tax1099KFilerType.EPF,
                        label: "Electronic Payment Facilitator (EPF)",
                      },
                    ]}
                  />
                </FormItem>
              </Box>
            )}

            {accessTaxEOYWrite && ongoingYear && (
              <Form.Control shouldUpdate>
                {({ isFieldsTouched }) => (
                  <Text align="right">
                    <Button htmlType="submit" type={isFieldsTouched() ? "primary" : "default"} loading={taxMerchantYearStatus === BaseStatus.LOADING}>
                      Save Tax Profile
                    </Button>
                  </Text>
                )}
              </Form.Control>
            )}
          </Form>
        )}
      </LogoLoader>
    </Container>
  );
}
