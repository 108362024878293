import MerchantOfflinePaymentsTemplate from "assets/merchant_tax_reporting_offline_payments_upload_template.20241202.1.zip";
import AUMerchantOfflinePaymentsTemplate from "assets/au_merchant_tax_reporting_offline_payments_upload_template.20241202.1.zip";
import { Icon, Text, UploadWindow } from "components";
import { MAX_FILE_SIZE } from "components/UploadWindow";
import React from "react";
import { useHistory } from "react-router-dom";
import { fileUpload } from "store/actions/files";
import { useShallowSelector } from "store/hooks";
import { BaseStatus } from "store/reducers/standardReducer";
import { UploadType } from "@trolley/common-frontend";
import { notifyError } from "store/actions/notifications";
import { useMerchantSettings } from "store/hooks/merchantSettings";

interface Props {
  visible: boolean;
  onClose(): void;
}
export default function AddOfflinePayments(props: Props) {
  const { onClose, visible } = props;
  const history = useHistory();
  const { data: merchantData } = useMerchantSettings();
  const fileUploadStatus = useShallowSelector((state) => state.files.fetchStatus.data);

  async function onUpload(file: FormData) {
    const supportedTaxRegions = merchantData?.supportedTaxRegions;
    const numOfRegionsEnabled = Object.values(supportedTaxRegions || {}).filter((isEnabled: boolean) => isEnabled).length;

    if (!supportedTaxRegions || numOfRegionsEnabled === 0) {
      notifyError("Merchant needs to setup a supported tax region before uploading offline payments");
      onClose();

      return;
    }

    // UK/EU only available multi-region configuration for 2024 reports. Multi-reporting in other configurations estimated to be developed in 2025.
    const isValidMultiRegionConf = numOfRegionsEnabled === 2 && supportedTaxRegions.UK && supportedTaxRegions.EU;

    if (numOfRegionsEnabled !== 1 && !isValidMultiRegionConf) {
      notifyError("Multi-region configuration is only available for UK and EU");
      onClose();

      return;
    }

    try {
      const id = await fileUpload(UploadType.DAC_7_UPLOAD, file);
      onClose();
      history.push(`/payments/offline-payments-dac7/upload/${id}`);
    } catch (e) {
      onClose();
      notifyError("File is invalid");
    }
  }

  return (
    <UploadWindow
      title="Upload offline payments"
      onClose={onClose}
      onSubmit={onUpload}
      loading={fileUploadStatus === BaseStatus.LOADING}
      visible={visible}
      maxSize={MAX_FILE_SIZE}
    >
      <Text padded align="center">
        <p>
          <a href={merchantData?.supportedTaxRegions?.AU ? AUMerchantOfflinePaymentsTemplate : MerchantOfflinePaymentsTemplate}>
            Download Offline Payments CSV Template
          </a>
          <Text type="secondary" size="small">
            <Icon type="circle-info" left color="blue" />
            Updated on December 2, 2024
          </Text>
        </p>
        <p>Please download the sample CSV file and re-upload with all the offline payments information you wish to add.</p>
        Maximum upload file size: {MAX_FILE_SIZE} MB.
      </Text>
    </UploadWindow>
  );
}
