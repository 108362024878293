import { Access, getPayoutMethodLabel } from "@trolley/common-frontend";
import {
  Button,
  ButtonDelete,
  ButtonDropdown,
  ColumnsDisplay,
  CurrencyDisplay,
  DateDisplay,
  Divider,
  Grid,
  Heading,
  Icon,
  LabelTooltip,
  Menu,
  MixedColumn,
  RecordCount,
  Table,
  tableColumnFilter,
  TableColumnsType,
  TableFilters,
  TableName,
  Text,
  useColumnsDisplay,
} from "components";
import { PaymentTaxRecordDAC7Edit } from "features/paymentTax";
import { useRecipientEditable } from "features/recipient";
import { TaxProfileDisplay } from "features/taxProfile";
import React, { useEffect, useState } from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { DAC7Tax, DAC7TaxQuery, deletePaymentDac7Taxes } from "store/actions/paymentDac7Taxes";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { usePaymentDac7Taxes } from "store/hooks/paymentDac7Taxes";
import { useAccess } from "store/hooks/user";
import { getPaymentCategoryLabel, getPaymentReasonLabel } from "utils/helpers";
import { useWindowSize } from "utils/hooks";

export const DEFAULT_QUERY: DAC7TaxQuery = {
  page: 1,
  pageSize: 10,
  sortBy: ["desc"],
};

interface Props {
  recipientId: string;
}

enum ColumnKeys {
  EXTERNAL_ID = "externalId",
  FEES_AMOUNT = "feesAmount",
  TAX_AMOUNT = "taxAmount",
  TAX_PROFILE = "taxProfileId",
  CATEGORY = "category",
  TAX_REPORTABLE = "taxReportable",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  PROCESSED_AT = "processedAt",
  PAYOUT_METHOD = "payoutMethod",
  FEES_TAXES_AMOUNT = "feesTaxesAmount",
  PAYMENT_REASON = "paymentReason",
}

const defaultColumnsDisplay: Record<ColumnKeys, MixedColumn> = {
  [ColumnKeys.FEES_AMOUNT]: true,
  [ColumnKeys.TAX_AMOUNT]: true,
  [ColumnKeys.FEES_TAXES_AMOUNT]: true,
  [ColumnKeys.PAYMENT_REASON]: true,
  [ColumnKeys.EXTERNAL_ID]: {
    label: "External ID",
    value: true,
  },
  [ColumnKeys.CATEGORY]: {
    label: "Payment Purpose",
    value: true,
  },
  [ColumnKeys.TAX_REPORTABLE]: {
    label: "Taxable",
    value: true,
  },
  [ColumnKeys.PAYOUT_METHOD]: {
    label: "Payout Method",
    value: true,
  },
  [ColumnKeys.TAX_PROFILE]: {
    label: "Tax Profile",
    value: true,
  },
  [ColumnKeys.PROCESSED_AT]: {
    label: "Processed On",
    value: true,
  },
  [ColumnKeys.CREATED_AT]: {
    label: "Created On",
    value: true,
  },
  [ColumnKeys.UPDATED_AT]: {
    label: "Updated On",
    value: true,
  },
};

export default function RecipientDac7OfflinePayments(props: Props) {
  const { recipientId } = props;
  const { isMobile } = useWindowSize();
  const [query, setQueryState] = useState({ ...DEFAULT_QUERY, recipientIds: [recipientId], withPayment: false });
  const accessPaymentWrite = useAccess(Access.PAYMENTS_WRITE);
  const profileEditable = useRecipientEditable();
  const [allSelected, setAllSelected] = useState(false);
  const [editDac7Tax, setEditDac7Tax] = useState<Partial<DAC7Tax> | undefined>();
  const [selectedDac7Taxes, setSelectedDac7Taxes] = useState<DAC7Tax[]>([]);
  const { data: dac7Taxes, status: dac7TaxStatus, error: dac7TaxesErrors } = usePaymentDac7Taxes(query);
  const { data: merchantSettings, status: merchantSettingsStatus } = useMerchantSettings();
  const isAUMarketplace = !!merchantSettings?.supportedTaxRegions?.AU;
  const [displayedColumns, setDisplayedColumns] = useColumnsDisplay(TableName.RECIPIENT_OFFLINE_PAYMENTS, defaultColumnsDisplay);

  useEffect(() => {
    setSelectedDac7Taxes([]);
    setAllSelected(false);
  }, [query]);

  async function onDeleteOfflinePayments() {
    if (selectedDac7Taxes.length > 0) {
      try {
        await deletePaymentDac7Taxes(selectedDac7Taxes.map((op) => op.id));
        notifySuccess("Offline payments deleted");
        setSelectedDac7Taxes([]);
      } catch (errors) {
        notifyError("Deleting offline payments failed");
      }
    }
  }

  return (
    <>
      <Divider transparent margin="medium" />
      <Heading
        tag="h2"
        extraActions={
          profileEditable &&
          accessPaymentWrite && (
            <ButtonDropdown
              type="primary"
              onClick={() => {
                setEditDac7Tax({ recipientId });
              }}
              overlay={
                <Menu
                  onClick={async (params) => {
                    const status = params.key as "manual";
                    switch (status) {
                      case "manual":
                        setEditDac7Tax({ recipientId });
                        break;
                    }
                  }}
                >
                  <Menu.Item key="manual">
                    <Icon type="plus" left />
                    Add Offline Payment
                  </Menu.Item>
                </Menu>
              }
              style={{ width: isMobile ? "100%" : "auto" }}
            >
              <Icon type="plus" left />
              Add Offline Payment
            </ButtonDropdown>
          )
        }
      >
        <RecordCount prefix="Showing" value={dac7Taxes.meta.records} one="1 offline payment" other="# offline payments" />
      </Heading>

      <TableFilters
        showSearch
        onChange={(updates) => {
          setQueryState((state) => ({
            ...state,
            page: 1,
            ...updates,
          }));
        }}
        query={query}
        suffix={<ColumnsDisplay columns={displayedColumns} onChange={setDisplayedColumns} />}
      >
        {selectedDac7Taxes.length > 0 && (
          <Grid padding="small">
            {!allSelected && (
              <Grid.Item>
                <ButtonDelete title="Delete the selected offline payments?" onConfirm={onDeleteOfflinePayments}>
                  Delete
                </ButtonDelete>
              </Grid.Item>
            )}

            {!allSelected && selectedDac7Taxes.length === dac7Taxes.records.length && dac7Taxes.meta.records > selectedDac7Taxes.length && (
              <Grid.Item>
                <Button
                  type="link"
                  onClick={() => {
                    setAllSelected(true);
                  }}
                >
                  <RecordCount value={dac7Taxes.meta.records} other="Select all # offline payments" />
                </Button>
              </Grid.Item>
            )}

            {allSelected && (
              <Grid.Item>
                <Button
                  type="link"
                  onClick={() => {
                    setSelectedDac7Taxes([]);
                    setAllSelected(false);
                  }}
                >
                  Clear Selection
                </Button>
              </Grid.Item>
            )}
          </Grid>
        )}
      </TableFilters>

      <Table<DAC7Tax>
        status={dac7TaxStatus && merchantSettingsStatus}
        errors={dac7TaxesErrors}
        columns={
          [
            { title: "External ID", key: ColumnKeys.EXTERNAL_ID, dataIndex: "externalId" },
            {
              title: "Fees Amount",
              key: ColumnKeys.FEES_AMOUNT,
              dataIndex: "platformFee",
              align: "right",
              render: (platformFee: DAC7Tax["platformFee"]) => <CurrencyDisplay value={platformFee.value} currency={platformFee.currency} />,
            },
            {
              title: "Tax Amount",
              key: ColumnKeys.TAX_AMOUNT,
              dataIndex: "tax",
              align: "right",
              render: (tax: DAC7Tax["tax"]) => <CurrencyDisplay value={tax.value} currency={tax.currency} />,
            },
            ...(isAUMarketplace
              ? [
                  {
                    title: "Fees Taxes Amount",
                    key: ColumnKeys.FEES_TAXES_AMOUNT,
                    dataIndex: "feesTaxes",
                    align: "right",
                    render: (feesTaxes: DAC7Tax["feesTaxes"]) => <CurrencyDisplay value={feesTaxes?.value ?? 0} currency={feesTaxes?.currency ?? "AUD"} />,
                  },
                ]
              : []),
            {
              key: ColumnKeys.CATEGORY,
              title: <LabelTooltip type="category" />,
              dataIndex: "category",
              render: (category: DAC7Tax["category"]) => getPaymentCategoryLabel(category),
            },
            ...(isAUMarketplace
              ? [
                  {
                    title: "Payment Reason",
                    key: ColumnKeys.PAYMENT_REASON,
                    dataIndex: "paymentReason",
                    align: "right",
                    render: (paymentReason: DAC7Tax["paymentReason"]) => getPaymentReasonLabel(paymentReason),
                  },
                ]
              : []),
            {
              title: <LabelTooltip type="taxReportable" />,
              key: ColumnKeys.TAX_REPORTABLE,
              dataIndex: "taxReportable",
              render: (taxReportable: boolean) => (
                <Text capitalize wrap={false}>
                  {taxReportable ? "Taxable" : "Exempt"}
                </Text>
              ),
            },
            {
              title: "Payout Method",
              key: ColumnKeys.PAYOUT_METHOD,
              dataIndex: "payoutMethod",
              render: (pm: string) => <Text wrap={false}>{getPayoutMethodLabel(pm, true)}</Text>,
            },
            {
              key: ColumnKeys.TAX_PROFILE,
              title: "Tax Profile",
              dataIndex: "taxProfileId",
              render: (taxProfileId: DAC7Tax["taxProfileId"]) => {
                return <TaxProfileDisplay taxProfileId={taxProfileId} showSignedDate showStatus />;
              },
            },
            {
              title: "Processed On (UTC)",
              key: ColumnKeys.PROCESSED_AT,
              dataIndex: "processedAt",
              render: (date: string) => <DateDisplay value={date} showUtc time={false} />,
            },
            {
              title: "Created On",
              key: ColumnKeys.CREATED_AT,
              dataIndex: "createdAt",
              sorter: true,
              sortOrder: query.orderBy?.[0] === "createdAt" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
              render: (date: string) => <DateDisplay value={date} />,
            },
            {
              title: "Updated On",
              key: ColumnKeys.UPDATED_AT,
              dataIndex: "updatedAt",
              sorter: true,
              sortOrder: query.orderBy?.[0] === "updatedAt" ? (query.sortBy?.[0] === "asc" ? "ascend" : "descend") : undefined,
              render: (date: string) => <DateDisplay value={date} />,
            },
          ].filter(tableColumnFilter(displayedColumns)) as TableColumnsType<DAC7Tax>
        }
        emptyProps={{ description: "No offline payments found" }}
        dataSource={dac7Taxes.records}
        pagination={{
          current: query.page,
          pageSize: query.pageSize,
          total: dac7Taxes.meta.records,
        }}
        onRow={(record: DAC7Tax) => ({
          onClick: () => {
            setEditDac7Tax(record);
          },
        })}
        rowSelection={
          accessPaymentWrite
            ? {
                fixed: true,
                onChange: (keys: string[], selectedRows: DAC7Tax[] = []) => {
                  setSelectedDac7Taxes(selectedRows);
                },
                selectedRowKeys: selectedDac7Taxes.map((r) => r.id),
                onSelect(record, selected) {
                  if (!selected) {
                    setAllSelected(false);
                  }
                },
                onSelectAll(selected: boolean) {
                  if (!selected) {
                    setAllSelected(false);
                  }
                },
              }
            : undefined
        }
        onQueryChange={(update) => {
          setQueryState((state) => ({
            ...state,
            page: 1,
            ...update,
          }));
        }}
      />

      <PaymentTaxRecordDAC7Edit
        dac7Tax={editDac7Tax}
        onClose={() => {
          setEditDac7Tax(undefined);
        }}
      />
    </>
  );
}
