import { batch } from "react-redux";
import store from "store";
import { loadRecipient } from "store/actions/recipients";
import { TaxForm, TaxFormsQuery } from "store/actions/taxForms";
import { OpCode, standardDispatch } from "store/dispatcher";
import { Mapped } from "store/reducers/standardReducer";
import * as request from "services/request";
import * as types from "@trolley/common-frontend";
import { computeID, isLoaded } from "./actionUtils";

const TAX_LABELS: Record<types.TaxFormType | types.UsUploadTaxFormType | types.EndOfYearFormType, string> = {
  [types.TaxFormType.W9]: "W-9",
  [types.TaxFormType.W8BEN]: "W-8BEN",
  [types.TaxFormType.W8BENE]: "W-8BEN-E",
  [types.TaxFormType.US_UPLOAD]: "Uploaded Form",
  [types.TaxFormType.F8233]: "8233",
  [types.UsUploadTaxFormType.W8IMY]: "W-8IMY",
  [types.UsUploadTaxFormType.W8ECI]: "W-8ECI",
  [types.UsUploadTaxFormType.W8EXP]: "W-8EXP",
  [types.UsUploadTaxFormType.W4]: "W-4",
  [types.EndOfYearFormType.F1099]: "1099-MISC",
  [types.EndOfYearFormType.F1099_K]: "1099-K",
  [types.EndOfYearFormType.F1099_NEC]: "1099-NEC",
  [types.EndOfYearFormType.F1042]: "1042-S",
  [types.EndOfYearFormType.NONE]: "No Form",
};

export function getTaxFormLabel(type: types.TaxFormType | types.UsUploadTaxFormType | types.EndOfYearFormType | null | undefined) {
  return (type && TAX_LABELS[type]) || type || "";
}

const WarningLabels: Record<types.TaxFormWarnings, string> = {
  [types.TaxFormWarnings.UPLOAD_INCOMPLETE]: "The tax information is still invalid.",
  [types.TaxFormWarnings.INCOMPLETE_ADDRESS]: "The address information provided on the form looks incomplete.",
  [types.TaxFormWarnings.DIFFERENT_COUNTRY]: "The recipient's country does not match the country provided on the form.",
  [types.TaxFormWarnings.FOREIGN_TIN_INVALID]: "The Foreign Tax Identification Number on the form looks invalid.",
  [types.TaxFormWarnings.FOREIGN_TIN_MISSING]: "The Foreign Tax Identification Number is missing from the form.",
  [types.TaxFormWarnings.US_TIN_FAILED]: "The US Tax Identification Number on the form is invalid.",
  [types.TaxFormWarnings.TIN_MATCH_RESULTS_ARE_PENDING]: "The TIN Match results are pending.",
};

export const WithholdingReasonLabels: Record<types.TaxWithholdingReasons, string> = {
  [types.TaxWithholdingReasons.RENT_SUBJECT_TO_WITHHOLDING]: "Certified that they are subject to backup withholding.",
  [types.TaxWithholdingReasons.SUBJECT_TO_WITHHOLDING]: "Certified that they are subject to backup withholding.",
  [types.TaxWithholdingReasons.NOT_SUBJECT_TO_WITHHOLDING]: "Certified that they are not subject to backup withholding.",
  [types.TaxWithholdingReasons.NO_US_CERTIFICATION]: "Certified that they do not perform any US Activities.",
  [types.TaxWithholdingReasons.TREATY_TAX_RATE]: "This reflects the appropriate treaty withholding rate for their country of residence.",
  [types.TaxWithholdingReasons.NO_TAXID_PROVIDED]: "They have not provided a Tax Identification Number.",
  [types.TaxWithholdingReasons.NO_TAX_TREATY]: "Their country of residence is not included in the list of treaty countries.",
  [types.TaxWithholdingReasons.UNKNOWN_FORM_UPLOAD]: "An unknown form has been uploaded.",
  [types.TaxWithholdingReasons.TIN_INVALID]: "Failed US TIN Matching. A new tax form is required.",
  [types.TaxWithholdingReasons.NO_RESIDENCE_COUNTRY]: "Their country of residence is missing.",
  [types.TaxWithholdingReasons.INCOMPLETE_FORM]: "The tax form is incomplete.",
};

export function getTaxFormWarningLabel(warning: types.TaxFormWarnings): string {
  return (warning && WarningLabels[warning]) || warning || "";
}

function reset() {
  batch(() => {
    standardDispatch(OpCode.RESET, "recipients");
    standardDispatch(OpCode.RESET, "taxForms");
    standardDispatch(OpCode.RESET, "recipientTaxForms");
    standardDispatch(OpCode.RESET, "eoyTaxForms");
  });
}

export function loadRecipientTaxForms(recipientId: string, query: TaxFormsQuery, force: boolean = false) {
  const { recipientTaxForms } = store.getState();
  const id = computeID({ ...query, recipientId });

  if (force || !isLoaded(recipientTaxForms.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "recipientTaxForms", { id });

    request
      .POST<types.TaxForm.ListResult>("/v1/tax-form/search", { query: { ...query, recipientId } })
      .then((res) => {
        const ids: string[] = [];
        const mappedForms: Mapped<TaxForm> = {};
        res.body.taxForms.forEach((tf) => {
          mappedForms[tf.id] = tf;
          ids.push(tf.id);
        });
        batch(() => {
          standardDispatch(OpCode.DATA, "recipientTaxForms", {
            id,
            data: {
              records: ids,
              meta: res.body.meta,
            },
          });
          standardDispatch(OpCode.DATA, "taxForm", {
            bulk: mappedForms,
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "recipientTaxForms", {
          id,
          errors,
        });
      });
  }

  return id;
}

export async function updateRecipientTaxForms(recipientId: string, taxId: string, changes: types.TaxForm.UsUpload) {
  standardDispatch(OpCode.LOADING, "taxForm", {
    id: taxId,
  });

  try {
    const { body } = await request.PATCH<types.TaxForm.Result>(`/v1/recipients/${recipientId}/tax/${taxId}`, { query: changes });
    standardDispatch(OpCode.DATA, "taxForm", {
      id: taxId,
      data: body.taxForm,
    });
    loadRecipient(recipientId, true);
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxForm", {
      id: taxId,
      errors,
    });
    throw errors;
  }
}

export async function voidRecipientTaxForm(recipientId: string, taxId: string, reason?: types.TaxFormVoidReason) {
  standardDispatch(OpCode.LOADING, "taxForm", { id: taxId });

  try {
    await request.POST<{}>(`/v1/recipients/${recipientId}/tax/${taxId}/void`, { query: { reason } });
    standardDispatch(OpCode.UPDATE, "taxForm", {
      id: taxId,
      update: {
        status: types.TaxStatus.VOIDED,
      },
    });
    standardDispatch(OpCode.RESET, "recipientTaxForms");
    loadRecipient(recipientId, true);
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxForm", {
      errors,
      id: taxId,
    });
    throw errors;
  }
}

export async function approveRecipientTaxForm(recipientId: string, taxId: string) {
  standardDispatch(OpCode.LOADING, "taxForm", { id: taxId });

  try {
    await request.POST<{}>(`/v1/recipients/${recipientId}/tax/${taxId}/reviewed`);
    batch(() => {
      standardDispatch(OpCode.LOADING, "taxForm", { id: taxId, loading: false });
      standardDispatch(OpCode.RESET, "recipientTaxForms");
    });
    loadRecipient(recipientId, true);
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxForm", {
      errors,
      id: taxId,
    });
    throw errors;
  }
}

export async function submitRecipientTaxForm(recipientId: string, taxId: string, name: string) {
  standardDispatch(OpCode.LOADING, "taxForm", { id: taxId });

  try {
    await request.POST<{}>(`/v1/recipients/${recipientId}/tax/${taxId}/sign`, {
      query: { signature: name },
    });
    batch(() => {
      standardDispatch(OpCode.LOADING, "taxForm", { id: taxId, loading: false });
      standardDispatch(OpCode.RESET, "recipientTaxForms");
    });
    loadRecipient(recipientId, true);
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "taxForm", {
      errors,
      id: recipientId,
    });
    throw errors;
  }
}

export async function uploadRecipientTaxDocument(recipientId: string, file: FormData) {
  const res = await request.POST<types.TaxForm.Result>(`/v1/recipients/${recipientId}/tax/upload`, {
    isUpload: true,
    body: file,
  });
  reset();

  return res.body.taxForm;
}
