import { EndOfYearFormType, EoyTaxReportStatus } from "@trolley/common-frontend";
import { Tabs } from "antd";
import dayjs from "dayjs";
import React from "react";
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";

import { Badge, Box, Button, Container, DateDisplay, Dropdown, Heading, Icon, Menu, Text } from "components";
import MerchantTaxInfo from "pages/TaxCenter/EndOfYear/USTax/MerchantTaxInfo";
import ReviewEarnings from "pages/TaxCenter/EndOfYear/USTax/ReviewEarnings";
import { EoyTaxQuery } from "store/actions/eoyTaxForms";
import { getTaxFormLabel } from "store/actions/recipientTaxForms";
import { TaxMerchantYear } from "store/actions/taxMerchantYear";
import { useEoySummary } from "store/hooks/eoySummary";
import { useMerchantTaxYear } from "store/hooks/taxMerchantYear";
import { BaseStatus } from "store/reducers/standardReducer";
import css, { createUseStyle } from "style/classname";
import { LAST_YEAR, TAX_PATHS, US_TAX_YEARS } from "../..";
import TaxStatements from "./TaxStatements";

export enum Pages {
  EARNINGS = "review-earnings",
  DELIVERY1099K = "1099-K-statements",
  DELIVERY1099NEC = "1099-NEC-statements",
  EFILE1099NEC = "1099-NEC-efile",
  DELIVERY1099MISC = "1099-MISC-statements",
  EFILE1099MISC = "1099-MISC-efile",
  DELIVERY1042 = "1042-statements",
  EFILE1042 = "1042-efile",
  PROFILE = "business-tax-profile",
}

export function getLastYear() {
  const Launch_Date_Tax_Center = "2024-12-01"; // date to update every year in december
  const LAST_YEAR = dayjs().isBefore(dayjs(Launch_Date_Tax_Center, "YYYY-MM-DD"))
    ? dayjs().subtract(1, "year").year()
    : dayjs(Launch_Date_Tax_Center, "YYYY-MM-DD").year();

  return LAST_YEAR;
}

export enum StatementActions {
  SUBMIT = "submit",
  VOID = "void",
  SEND = "send",
}

export function isStatementActionLocked(taxYear: number, action: StatementActions) {
  // dates to be updated every year
  const dates = {
    [StatementActions.SEND]: "2025-01-01",
    [StatementActions.VOID]: "2025-01-01",
    [StatementActions.SUBMIT]: "2025-01-01",
  };

  const launchActionDate = dayjs(dates[action], "YYYY-MM-DD");
  const correspondingTaxYear = launchActionDate.subtract(1, "year").year();
  if (taxYear < correspondingTaxYear) return false;

  return dayjs().isBefore(launchActionDate);
}

/**
 *
 * @param taxMerchantYear
 * @param formType
 * @param eFiling for downloading the FIRE E-File format
 */
export function isProfileComplete(taxMerchantYear?: TaxMerchantYear, formType?: EndOfYearFormType, eFiling?: boolean): boolean {
  if (!taxMerchantYear) {
    return false;
  }

  if (!formType || [EndOfYearFormType.F1099, EndOfYearFormType.F1099_NEC].includes(formType)) {
    const fieldsRequiredFor1099 = ["taxUsId"];

    /**
     * // irsControlCode  TCC code is no longer needed. Trolley now has its own to generate E-Files
     */
    // if (eFiling) {
    //   fieldsRequiredFor1099.push("irsControlCode");
    // }

    if (fieldsRequiredFor1099.some((key) => !taxMerchantYear[key])) {
      return false;
    }
  }

  if (!formType || [EndOfYearFormType.F1042].includes(formType)) {
    const fieldsRequiredFor1042 = [
      // "taxForeignId", // not required
      "tax1042chapter3code",
      "tax1042chapter4code",
    ];

    /**
     * // irsControlCode  TCC code is no longer needed. Trolley now has its own to generate E-Files
     */
    // if (eFiling) {
    //   fieldsRequiredFor1042.push("irsControlCode1042");
    // }

    if (fieldsRequiredFor1042.some((key) => !taxMerchantYear[key])) {
      return false;
    }
  }

  return [
    "nonUSentity",
    "organizationName",
    "mailingAddress",
    "mailingCity",
    "mailingRegion",
    "mailingPostalCode",
    "mailingCountry",
    "contactName",
    "contactPhone",
    "contactEmail",
  ].every((key) => taxMerchantYear[key] !== "" && taxMerchantYear[key] !== null);
}

const QUERIES: EoyTaxQuery[] = [
  // inReview:
  {
    pageSize: 0,
    status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
    requireTaxForm: true,
  },
  // inReview1099:
  {
    pageSize: 0,
    formTypes: [EndOfYearFormType.F1099],
    status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
    requireTaxForm: true,
  },
  // inProgress1099:
  {
    pageSize: 0,
    formTypes: [EndOfYearFormType.F1099],
    status: [EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.SENT],
    requireIrsSubmittedAt: false,
  },
  // completed1099:
  {
    pageSize: 0,
    formTypes: [EndOfYearFormType.F1099],
    status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
    requireIrsSubmittedAt: true,
  },

  // inReview1099-K:
  {
    pageSize: 0,
    formTypes: [EndOfYearFormType.F1099_K],
    status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
    // requireTaxForm: true,
  },
  // inProgress1099-K:
  {
    pageSize: 0,
    formTypes: [EndOfYearFormType.F1099_K],
    status: [EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.SENT],
    requireIrsSubmittedAt: false,
  },
  // completed1099-K:
  {
    pageSize: 0,
    formTypes: [EndOfYearFormType.F1099_K],
    status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
    requireIrsSubmittedAt: true,
  },

  // inReview1099NEC:
  {
    pageSize: 0,
    formTypes: [EndOfYearFormType.F1099_NEC],
    status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
    requireTaxForm: true,
  },
  // inProgress1099NEC:
  {
    pageSize: 0,
    formTypes: [EndOfYearFormType.F1099_NEC],
    status: [EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.SENT],
    requireIrsSubmittedAt: false,
  },
  // completed1099NEC:
  {
    pageSize: 0,
    formTypes: [EndOfYearFormType.F1099_NEC],
    status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
    requireIrsSubmittedAt: true,
  },

  // inReview1042:
  {
    pageSize: 0,
    formTypes: [EndOfYearFormType.F1042],
    status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
    requireTaxForm: true,
  },
  // inProgress1042:
  {
    pageSize: 0,
    formTypes: [EndOfYearFormType.F1042],
    status: [EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.SENT],
    requireIrsSubmittedAt: false,
  },
  // completed1042:
  {
    pageSize: 0,
    formTypes: [EndOfYearFormType.F1042],
    status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
    requireIrsSubmittedAt: true,
  },
];

export function getDueDate(eoyFormType: EndOfYearFormType, type: "delivery" | "efile", taxYear: number) {
  const currentYear = taxYear + 1;
  if (eoyFormType === EndOfYearFormType.F1099_NEC) {
    return `${currentYear}-01-31`;
  } else if (eoyFormType === EndOfYearFormType.F1099_K) {
    return type === "delivery" ? `${currentYear}-01-31` : `${currentYear}-03-31`;
  } else if (eoyFormType === EndOfYearFormType.F1042) {
    return `${currentYear}-03-17`;
  } else if (eoyFormType === EndOfYearFormType.F1099) {
    return type === "delivery" ? `${currentYear}-01-31` : `${currentYear}-03-31`;
  }

  return "";
}

export default function EndOfYearPage() {
  const styledTabs = useStyledTabs();
  const {
    push,
    replace,
    location: { pathname, search },
  } = useHistory();
  const params = useParams<{ taxYear?: string }>();
  const taxYear = Number(params.taxYear);
  const hasNEC = taxYear >= 2020;
  const { data: merchantTaxYear } = useMerchantTaxYear(taxYear);
  const { data: eoySummary, status: summaryStatus } = useEoySummary(QUERIES.map((q) => ({ ...q, taxYear })));
  const [
    inReviewCount,
    inReview1099Count,
    inProgress1099Count,
    completed1099Count,
    inReview1099KCount,
    inProgress1099KCount,
    completed1099KCount,
    inReview1099NECCount,
    inProgress1099NECCount,
    completed1099NECCount,
    inReview1042Count,
    inProgress1042Count,
    completed1042Count,
  ] = eoySummary;
  const incomplete1099Count = inReview1099Count + inProgress1099Count;
  const incomplete1099KCount = inReview1099KCount + inProgress1099KCount;
  const incomplete1099NECCount = inReview1099NECCount + inProgress1099NECCount;
  const incomplete1042Count = inReview1042Count + inProgress1042Count;
  const isBusinessProfileComplete =
    isProfileComplete(merchantTaxYear) &&
    (incomplete1099Count + completed1099Count + incomplete1099KCount + completed1099KCount + incomplete1099NECCount + completed1099NECCount === 0 ||
      isProfileComplete(merchantTaxYear, EndOfYearFormType.F1099, true)) &&
    (incomplete1042Count + completed1042Count === 0 || isProfileComplete(merchantTaxYear, EndOfYearFormType.F1042, true));
  const baseUrl = `${TAX_PATHS.EOY_REPORTING}/${taxYear}`;
  const basePath = `${TAX_PATHS.EOY_REPORTING}/:taxYear(\\d{4})`;

  if (Number.isNaN(taxYear)) {
    replace({ pathname: [TAX_PATHS.EOY_REPORTING, LAST_YEAR].join("/") });
  }

  return (
    <Container>
      <Heading>
        End of Year Review &amp; Filing for&nbsp;
        <Dropdown
          overlay={
            <Menu
              onClick={(e) => {
                const newTaxYear = e.key;
                push({
                  pathname: pathname.replace(String(taxYear), newTaxYear),
                  search,
                });
              }}
            >
              {US_TAX_YEARS.map((year) => {
                return (
                  <Menu.Item key={year} disabled={taxYear === year}>
                    Tax Year {year}
                  </Menu.Item>
                );
              })}
            </Menu>
          }
        >
          <Button type="text" size="large" style={{ fontSize: "inherit", lineHeight: 1 }}>
            Tax Year {taxYear}
            <Icon type="angle-down" right />
          </Button>
        </Dropdown>
      </Heading>

      <Tabs
        type="card"
        activeKey={pathname}
        onChange={(id: Pages) => {
          push(id, {
            scrollToTop: false,
          });
        }}
        className={styledTabs}
      >
        <Tabs.TabPane
          key={`${baseUrl}/${Pages.EARNINGS}`}
          tab={
            <div>
              Review Earnings
              {summaryStatus === BaseStatus.LOADED &&
                (inReviewCount > 0 ? <Badge count={inReviewCount} offset={[4, -6]} /> : <Icon.Status type="success" right />)}
            </div>
          }
        />
        {incomplete1099Count + completed1099Count > 0 && (
          <Tabs.TabPane
            key={`${baseUrl}/${Pages.DELIVERY1099MISC}`}
            tab={
              <div>
                {getTaxFormLabel(EndOfYearFormType.F1099)} Statements
                {summaryStatus === BaseStatus.LOADED &&
                  (incomplete1099Count > 0 ? <Badge count={incomplete1099Count} offset={[4, -6]} /> : <Icon.Status type="success" right />)}
                <Text size="small" type="secondary">
                  Due <DateDisplay value={getDueDate(EndOfYearFormType.F1099, "delivery", taxYear)} time={false} icon={false} />
                  <Icon.Hint
                    right
                    tooltip={
                      <>
                        <Text type="label">Deadline to send to recipients</Text>
                        <DateDisplay value={getDueDate(EndOfYearFormType.F1099, "delivery", taxYear)} time={false} />
                        <Text type="label">Deadline for IRS E-Filing</Text>
                        <DateDisplay value={getDueDate(EndOfYearFormType.F1099, "efile", taxYear)} time={false} />
                      </>
                    }
                  />
                </Text>
              </div>
            }
          />
        )}
        {taxYear >= 2023 && incomplete1099KCount + completed1099KCount > 0 && (
          <Tabs.TabPane
            key={`${baseUrl}/${Pages.DELIVERY1099K}`}
            tab={
              <div>
                {getTaxFormLabel(EndOfYearFormType.F1099_K)} Statements
                {summaryStatus === BaseStatus.LOADED &&
                  (incomplete1099KCount > 0 ? <Badge count={incomplete1099KCount} offset={[4, -6]} /> : <Icon.Status type="success" right />)}
                <Text size="small" type="secondary">
                  Due <DateDisplay value={getDueDate(EndOfYearFormType.F1099_K, "delivery", taxYear)} time={false} icon={false} />
                </Text>
              </div>
            }
          />
        )}
        {hasNEC && incomplete1099NECCount + completed1099NECCount > 0 && (
          <Tabs.TabPane
            key={`${baseUrl}/${Pages.DELIVERY1099NEC}`}
            tab={
              <div>
                {getTaxFormLabel(EndOfYearFormType.F1099_NEC)} Statements
                {summaryStatus === BaseStatus.LOADED &&
                  (incomplete1099NECCount > 0 ? <Badge count={incomplete1099NECCount} offset={[4, -6]} /> : <Icon.Status type="success" right />)}
                <Text size="small" type="secondary">
                  Due <DateDisplay value={getDueDate(EndOfYearFormType.F1099_NEC, "delivery", taxYear)} time={false} icon={false} />
                </Text>
              </div>
            }
          />
        )}

        {incomplete1042Count + completed1042Count > 0 && (
          <Tabs.TabPane
            key={`${baseUrl}/${Pages.DELIVERY1042}`}
            tab={
              <div>
                {getTaxFormLabel(EndOfYearFormType.F1042)} Statements
                {summaryStatus === BaseStatus.LOADED &&
                  (incomplete1042Count > 0 ? <Badge count={incomplete1042Count} offset={[4, -6]} /> : <Icon.Status type="success" right />)}
                <Text size="small" type="secondary">
                  Due <DateDisplay value={getDueDate(EndOfYearFormType.F1042, "delivery", taxYear)} time={false} icon={false} />
                </Text>
              </div>
            }
          />
        )}
        <Tabs.TabPane
          key={`${baseUrl}/${Pages.PROFILE}`}
          tab={
            <div>
              Business Tax Profile
              {isBusinessProfileComplete && <Icon.Status type="success" right />}
            </div>
          }
        />
      </Tabs>

      <Box className={styledTabContent}>
        <Switch>
          <Route path={`${basePath}/${Pages.DELIVERY1099MISC}`} render={() => <TaxStatements formType={EndOfYearFormType.F1099} />} />
          <Route path={`${basePath}/${Pages.DELIVERY1099K}`} render={() => <TaxStatements formType={EndOfYearFormType.F1099_K} />} />
          <Route path={`${basePath}/${Pages.DELIVERY1099NEC}`} render={() => <TaxStatements formType={EndOfYearFormType.F1099_NEC} />} />
          <Route path={`${basePath}/${Pages.DELIVERY1042}`} render={() => <TaxStatements formType={EndOfYearFormType.F1042} />} />

          <Route path={`${basePath}/${Pages.PROFILE}`} component={MerchantTaxInfo} />
          <Route path={`${basePath}/${Pages.EARNINGS}`} component={ReviewEarnings} />
          <Redirect to={`${basePath}/${Pages.EARNINGS}`} />
        </Switch>
      </Box>
    </Container>
  );
}

const useStyledTabs = createUseStyle(({ theme }) =>
  css`
    &.${theme.prefixCls}-tabs {
      .${theme.prefixCls}-tabs-nav {
        margin-bottom: 0;
        &::before {
          display: none;
        }
        .${theme.prefixCls}-tabs-nav-wrap {
          .${theme.prefixCls}-tabs-tab {
            align-items: flex-start;
            border-radius: 8px 8px 0 0;
            border-width: 0px;
            box-shadow: ${theme.boxShadowTertiary};
          }
        }
      }
    }
  `(),
);

const styledTabContent = css`
  &.box {
    border-top-left-radius: 0;
    margin-top: 0;
  }
`();
